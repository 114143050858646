.what {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  &__content {
    flex: 0 1 49%;
    padding: 2rem;
    color: $secondary;
    background: $primary;
    position: relative;
    margin-bottom: 2rem;
    min-height: 30rem;
    overflow: hidden;
    &:hover {
      .what__content--column {
        top: 100%;
      }
      .what__content--list {
        top: 0;
      }
    }
    &--column {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      padding: 2rem;
      display: inline-block;
      width: 100%;
      text-align: center;
      @include transition(550ms, 0ms, cubic-bezier(0.5, -0.01, 0.27, 1.55));
    }
    &--icon {
      font-size: 6rem;
      margin-bottom: 4rem;
    }
    &--head {
      font-size: 2.4rem;
      letter-spacing: -0.05rem;
      position: relative;
      margin-bottom: 1rem;
      padding-right: 1rem;
      margin-top: 0;
    }
    &--description {
      font-size: 1.6rem;
      letter-spacing: -0.033rem;
      line-height: 2.4rem;
      display: inline-block;
    }
    &--list {
      position: absolute;
      top: -100%;
      background: $secondary;
      color: $primary;
      border: 1rem solid $primary;
      @include transition(350ms, 0ms, ease-in-out);
      width: 100%;
      height: 100%;
      left: 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      list-style: none;
      &-item {
        margin: 0.5rem 0;
        color: $primary;
        font-weight: 500;
        font-size: 1.6rem;
        padding-left: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .what {
    min-height: 100%;
    &__content {
      flex: 1 1 100%;
      &--column {
        width: 100%;
      }
      &--head {
        width: auto;
        display: inline-block;
      }
      &--description {
        width: 100%;
      }
      &--list {
        width: 100%;
        padding: 4rem 2rem 0 2rem;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .what {
    min-height: 100%;
    &__content {
      flex: 1 1 100%;
      &--column {
        width: 100%;
      }
      &--head {
        font-size: 1.6rem;
        width: 100%;
      }
      &--list {
        width: 100%;
        padding: 4rem 2rem 0 2rem;
        columns: 1;
      }
    }
  }
}
