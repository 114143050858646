* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: 10px;
  font-family: "Montserrat", sans-serif; //font-family: "Consolas";
  font-weight: 400;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  position: relative;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Krona One", sans-serif;
  font-weight: 500;
}
h1 {
  font-size: 4.8rem;
  &.section-header {
    padding: 2rem 0;
    border-bottom: 0.8rem solid $primary;
    margin-bottom: 5rem;
    width: 100%;
    color: $primary;
  }
}

ul {
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
  display: block;
}
.content {
  width: 114rem;
  margin: 0 auto;
}

/*change the thinkness of the scrollbar here*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*add a shadow to the scrollbar here*/

/*this is the little scrolly dealio in the bar*/
::-webkit-scrollbar-thumb {
  background: $primary;
  height: 3px;
}
/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}
a {
  text-decoration: none;
  color: $secondary;
}
a:visited {
  color: $secondary;
}
.scrollTop {
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  width: 4rem;
  height: 4rem;
  background: $secondary;
  border: 1px solid $primary;
  pointer-events: none;
  opacity: 0;
  @include transition(250ms, 0ms, ease-in-out);
  cursor: pointer;
  &.reveal {
    opacity: 1;
    pointer-events: all;
  }
  &__arrow {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: block;
    width: 3rem;
    height: 2rem;
    background: url("../assets/images/arrow_r.svg");
    transform: rotate(-90deg);
    background-size: cover;
  }
}
.anchor {
  position: absolute;
  top: -5rem;
}
@media screen and (max-width: 1440px) {
  .anchor {
    top: 0;
  }
  .content,
  .contact__wrapper {
    padding: 2rem 2rem 0 2rem;
    width: 100%;
    min-height: 100vh;
    &.how {
      height: auto;
    }
  }
  h1 {
    &.section-header {
      padding: 2rem 0;
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 3.2rem;
    &.section-header {
      font-size: 2.8rem;
      margin-bottom: 2rem;
    }
  }
}
