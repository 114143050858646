.head {
  height: 100%;
  width: 100%;
  background: $primary;
  color: $secondary;
  text-align: center;
  background: url("./../assets/images/bg.jpg");
  background-size: cover;
  .separator {
    flex: 1;
  }
  &__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $primary;
    top: 0;
    left: 0;
    opacity: 0.7;
  }
  &__hero {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100% - 10rem);
    width: 114rem;
    margin: 0 auto;
    &--main {
      //animation taken from css tricks
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0; /* Gives that scrolling effect as the typing happens */
      letter-spacing: -0.1rem; /* Adjust as needed */
      position: relative;
      padding: 0;
      &:nth-child(2) {
        animation: typing 2s steps(40, end);
        animation-fill-mode: forwards;
        animation-delay: 1.5s;
        width: 38.5rem;
        opacity: 0;
        &:after {
          animation: dissapear 0s;
          animation-delay: 0.6s;
          animation-fill-mode: forwards;
        }
      }
      &:nth-child(3) {
        animation: typing2 2s steps(40, end);
        animation-delay: 2.1s;
        animation-fill-mode: forwards;
        width: 54rem;
        opacity: 0;
        &:after {
          animation: dissapear 0s;
          animation-delay: 1.2s;
          animation-fill-mode: forwards;
        }
      }
      &:nth-child(4) {
        animation: typing3 2s steps(40, end);
        animation-delay: 3s;
        animation-fill-mode: forwards;
        width: 80rem;
        opacity: 0;
        &:after {
          animation: caret 1s steps(1) infinite;
        }
      }

      &:after {
        content: "_";
        font-size: 4.8rem;
        position: absolute;
        bottom: 0;
        margin-left: 1rem;
      }
    }
    &--secondary {
      opacity: 0.9;
      font-size: 1.6rem;
      max-width: 60%;
      margin: 3rem 0 0rem 0;
      letter-spacing: -0.033rem;
      line-height: 2.4rem;
    }
    &--arrow {
      padding: 5rem 0;
      display: block;
      margin: 0 auto;
      transform: rotate(90deg);
      svg {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
}

.navigation {
  display: inline-block;
  width: 100%;
  color: $secondary;
  margin: 0rem auto;
  padding: 4rem 0 1rem 0;
  border-bottom: 0.2rem solid $secondary;
  width: 114rem;
  position: relative;
  @include transition(250ms, 0ms, ease-in-out);
  &.sticky {
    position: fixed;
    width: 100%;
    top: -10rem;
    left: 0;
    text-align: center;
    padding: 0;
    background: $secondary;
    transform: translateX(0);
    color: $primary;
    border-bottom: 0.1rem solid $primary;
    z-index: 10;
    &.visible {
      top: 0;
    }
    a {
      color: $primary;
    }
    .navigation__logo {
      background: url("./../assets/images/logo_blue.svg");
      width: 5.4rem;
      height: 4.8rem;
      background-size: contain;
      margin-left: 1rem;
      background-repeat: no-repeat;
    }
  }
  button {
    border: none;
    background: none;
    color: $secondary;
    font-size: 2rem;
    cursor: pointer;
  }
  &__logo {
    width: 6.4rem;
    height: 4.8rem;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    background: url("../assets/images/logo.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__nav {
    width: calc(100% - 6.4rem);
    vertical-align: middle;
    display: inline-block;
    font-size: 1.2rem;
    font-family: "Krona One", sans-serif;
    text-align: right;
    &--mobile {
      width: calc(100% - 6.4rem);
      vertical-align: middle;
      display: inline-block;
      font-size: 1.2rem;
      font-family: "Krona One", sans-serif;
      text-align: right;
      display: none;
      .mobile-navigation {
        left: 100%;
        position: fixed;
        top: 0;
        color: $primary;
        background: $secondary;
        width: 100vw;
        height: 100%;
        transform: translateZ(0);
        @include transition(750ms, 0ms, cubic-bezier(0.34, 0, 0.14, 1.01));
        &.expand {
          left: 0;
        }
        ul {
          text-align: left;
          margin-top: 10rem;
          padding: 0 6rem;
        }
        .navigation__nav__anchor {
          width: 100%;
          border-bottom: 0.1rem solid $primary;
          font-size: 2.8rem;
          cursor: pointer;
        }
        .close-nav {
          position: absolute;
          top: 2rem;
          right: 2rem;
          color: $primary;
        }
        a {
          color: $primary;
        }
      }
    }
    &__anchor {
      display: inline-block;
      vertical-align: middle;
      list-style: none;
      padding: 1rem;
      cursor: pointer;
    }
  }
}

@media screen and( max-width: 1440px) {
  .head {
    padding: 0 2rem;
    &__hero {
      width: 100%;
    }
  }
  .navigation {
    &.sticky {
      display: none;
    }
    width: 100%;
    &__nav {
      display: none;
      &--mobile {
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .head {
    &__hero {
      &--main {
        font-size: 2.2rem;
        &:after {
          font-size: 1.8rem;
        }
      }
      &--secondary {
        display: none;
      }
    }
  }
}
//navigation animations
@keyframes slide {
  from {
    top: -10rem;
  }
  to {
    top: 0rem;
  }
}

//typewriter animations
@keyframes caret {
  50% {
    opacity: 0;
  }
}
@keyframes dissapear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes typing {
  from {
    width: 0;
    opacity: 1;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}
@keyframes typing2 {
  from {
    width: 0;
    opacity: 1;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}
@keyframes typing3 {
  from {
    width: 0;
    opacity: 1;
  }
  to {
    width: 100%;
    opacity: 1;
  }
}
