@mixin transition($duration, $delay, $easing) {
  -ms-transition: $duration $delay $easing;
  -moz-transition: $duration $delay $easing;
  -webkit-transition: $duration $delay $easing;
  transition: $duration $delay $easing;
}

@mixin addBg($fileName, $bgSize, $bgRepeat) {
  background: url("./../assets/images/"+$fileName);
  background-size: $bgSize;
  background-repeat: $bgRepeat;
}
