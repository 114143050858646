.decorator {
  &__wrapper {
    position: absolute;
    top: 100%;
    margin-top: 34rem;
    left: calc(50% + 40rem);
    color: #0b107e;
    white-space: nowrap;
    &:last-of-type {
      transform: rotate(-90deg);
      right: calc(50% + 40rem);
      left: auto;
      top: auto;
      bottom: -150%;
    }
  }
  &__first {
    margin-right: 100%;
    display: inline-block;
    width: 100%;
  }
  &__second {
    margin-right: 100%;
    display: inline-block;
    width: 100%;
  }
  &--logo {
    width: 3.6rem;
    height: 2.6rem;
    background: url("./../assets/images/logo_blue.svg");
    background-size: cover;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 2rem;
  }
  &--text {
    font-size: 1rem;
    letter-spacing: 0.2rem;
    color: $primary;
  }
}
