.career {
  color: $primary;
  position: relative;
  p {
    opacity: 0.9;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -0.033rem;
    margin-bottom: 3rem;
    display: inline-block;
    &.career__shortdesc {
      margin-bottom: 0;
      margin-top: 4rem;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 1rem;
    scroll-snap-type: x proximity;
  }
  &__item {
    width: 40rem;
    height: 25rem;
    border: 1px solid $primary;
    color: $primary;
    position: relative;
    padding: 2.5rem;
    margin-right: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    scroll-snap-align: start;
    &:last-of-type {
      margin-right: 0;
    }
    &--overlay {
      z-index: 10;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba($primary, 0.3);
    }
    &--content {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 50%;
      height: calc(100% - 4rem);
      padding: 2rem;
      transform: translate3d(-50%, -50%, 0);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      background: $secondary;
      z-index: 10;
      white-space: normal;
      overflow-y: auto;

      h1 {
        margin-top: 0;
        display: inline-block;
        width: 80%;
        vertical-align: top;
      }
      h3 {
        display: inline-block;
        width: 100%;
        font-size: 2.4rem;
        margin-bottom: 0;
      }
    }
    &--close {
      &__wrapper {
        display: inline-block;
        width: 20%;
        text-align: right;
        vertical-align: top;
        position: -webkit-sticky;
        position: sticky;
        top: 0rem;
      }
      right: 2rem;
      font-size: 2.4rem;
      background: #0b107e;
      color: white;
      border: none;
      padding: 1rem 2rem;
    }
    &--introduction,
    &--responsibilities {
      p {
        opacity: 1;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.4rem;
        letter-spacing: 0.05rem;
        margin-bottom: 3rem;
        display: inline-block;
        -webkit-font-smoothing: antialiased;
        width: calc(100% - 7rem);
      }
    }
    &__list {
      display: inline-block;
      width: 100%;
      margin: 1.6rem 0;
      &-item {
        font-size: 1.4rem;
        list-style: disc;
        margin-bottom: 1rem;
        margin-left: 2rem;
        opacity: 1;
        line-height: 2.4rem;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        width: calc(100% - 7rem);
      }
    }
  }
  &__title {
    font-size: 1.8rem;
    font-family: "Krona One", sans-serif;
    text-align: left;
    font-weight: 500;
  }
  &__arrow {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    display: block;
    width: 3rem;
    height: 2rem;
    background: url("../assets/images/arrow_r.svg");

    background-size: cover;
  }
  &__subheader {
    font-size: 2.4rem;
    letter-spacing: -0.05rem;
  }
  &__resume {
    font-family: "Krona One", sans-serif;
    color: $secondary;
    background: $primary;
    border: 1px solid transparent;
    @include transition(250ms, 0ms, ease-in-out);
    padding: 0.8rem 2.4rem;
    font-size: 1.6rem;
    letter-spacing: -0.33px;
    line-height: 32px;
    margin-bottom: 8rem;
    display: inline-block;
    &:hover {
      border-color: $primary;
      background: $secondary;
      color: $primary;
    }
  }
  .details {
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: opacity 200ms;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity 200ms;
    }
  }
}

@media screen and (max-width: 700px) {
  .career {
    &__item {
      width: 100%;
      &--content {
        width: 90%;
      }
      &--close {
        font-size: 2.4rem;
        padding: 0.75rem 1.25rem;
      }
      &--name {
        white-space: normal;
      }
    }
  }
}
