.contact {
  color: $secondary;
  width: 114rem;
  margin: 0 auto;
  text-align: left;
  height: 100%;
  display: inline-block;
  &__wrapper {
    width: 100%;
    background: $primary;
    text-align: center;
    height: 50%;
    position: relative;
  }

  .section-header {
    padding-top: 8rem;
    border-bottom: none;
    color: $secondary;
    display: inline-block;
  }
  &__info {
    display: flex;

    flex-direction: column;
    height: calc(100% - 30rem);

    &--columns {
      flex: 0 1 auto;
    }
    &--wrapper {
      display: inline-block;
      width: 33%;
      vertical-align: top;
    }
  }
  .info__head {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    opacity: 1;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -0.033rem;
    &--big {
      font-family: "Krona One", sans-serif;
      font-size: 2.4rem;
      letter-spacing: -0.05rem;
    }
  }
  .separator {
    flex: 0 1 100%;
  }
}

@media screen and (max-width: 1440px) {
  .contact {
    width: 100%;
    &__wrapper {
      padding: 2rem 2rem 0 2rem;
      width: 100%;
      height: 100vh;
    }

    .info__head {
      font-size: 1.6rem;
      &--big {
        font-size: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .contact {
    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: unset;
      &--wrapper {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
    .info__head {
      font-size: 1.6rem;
      &--big {
        font-size: 1.6rem;
        margin: 0.5rem 0;
      }
    }
  }
}
