.who {
  position: relative;
  &__content {
    &--image {
      display: inline-block;
      vertical-align: bottom;
      width: 68rem;
      height: 45rem;
      background: url("../assets/images/people.png"), $primary;
      background-size: cover;
    }
    &--description {
      display: inline-block;
      vertical-align: bottom;
      text-align: left;
      font-size: 1.6rem;
      letter-spacing: -0.033rem;
      line-height: 2.4rem;
      padding-left: 6rem;
      color: $primary;
      width: calc(99% - 68rem);
    }
  }
}

@media screen and (max-width: 1440px) {
  .who {
    height: 100%;
    &__content {
      &--image {
        max-width: 68rem;
        max-height: 45rem;
        margin-bottom: 5rem;
      }
      &--description {
        width: 60%;
        padding-left: 0;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .who {
    height: 100%;
    &__content {
      &--image {
        width: 100%;
        height: 21rem;
      }
      &--description {
        width: 100%;
      }
    }
  }
}
