.how-type {
  &__content {
    width: 100%;
    padding: 15rem 20rem;
    text-align: center;
    color: $secondary;
    background: $primary;
    margin-bottom: 2rem;
    &--head {
      margin: 0 auto;
      color: $secondary;
      letter-spacing: -0.1rem;
      margin-bottom: 2rem;
    }
    &--description {
      margin: 0 auto;
      opacity: 0.9;
      font-size: 1.6rem;
      line-height: 2.4rem;
      letter-spacing: -0.033rem;
      margin-bottom: 8rem;
      display: inline-block;
    }
    &--list {
      &.technology,
      &.skills,
      &.experience {
        margin: 0 auto;
        padding: 0;
      }
      &.technology {
        .how-type__content--list-item {
          display: inline-block;
          margin: 0 2rem;
          width: 4.8rem;
          height: 4.8rem;
          vertical-align: middle;
          &.mysql {
            width: 9.2rem;
          }
        }
        &__icon {
          width: 100%;
          height: 100%;
          display: block;
          &.js {
            @include addBg("js.svg", contain, no-repeat);
          }
          &.vue {
            @include addBg("vue.svg", contain, no-repeat);
          }
          &.sf {
            @include addBg("sf.svg", contain, no-repeat);
          }
          &.angular {
            @include addBg("angular.svg", contain, no-repeat);
          }
          &.vs {
            @include addBg("vs.svg", contain, no-repeat);
          }
          &.react {
            @include addBg("react.svg", contain, no-repeat);
          }
          &.mysql {
            @include addBg("mysql.svg", contain, no-repeat);
          }
        }
      }
      .technology {
        &__icon {
          width: 100%;
          height: 100%;
          display: block;
          &.js {
            @include addBg("js.svg", contain, no-repeat);
          }
          &.vue {
            @include addBg("vue.svg", contain, no-repeat);
          }
          &.sf {
            @include addBg("sf.svg", contain, no-repeat);
          }
          &.angular {
            @include addBg("angular.svg", contain, no-repeat);
          }
          &.vs {
            @include addBg("vs.svg", contain, no-repeat);
          }
          &.react {
            @include addBg("react.svg", contain, no-repeat);
          }
          &.mysql {
            @include addBg("mysql.svg", contain, no-repeat);
          }
        }
      }
      &.skills {
        .how-type__content--list-item {
          font-family: "Playfair Display", serif;
          font-size: 2rem;
          font-weight: 700;
          letter-spacing: -0.042rem;
          text-align: center;
          padding: 0 2rem;
          margin: 0rem 3rem 9rem;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            bottom: -3rem;
            left: -2rem;
            display: block;
            width: 4.5rem;
            height: 8rem;
            background: url("../assets/images/frame_l.svg");
            background-size: cover;
            background-position: 50%;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: -3rem;
            right: -2rem;
            display: block;
            width: 4.5rem;
            height: 8rem;
            background: url("../assets/images/frame_r.svg");
            background-size: cover;
            background-position: 50%;
          }
        }
      }
      &.experience {
        .how-type__content--list-item {
          margin-right: 2.5rem;
          border: 1px solid $secondary;
          padding: 2rem;
          font-family: "Krona One", sans-serif;
          max-width: 22rem;
          &:last-of-type {
            margin-right: 0;
          }
        }
        .experience-big {
          font-size: 3.2rem;
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          font-weight: 700;
        }
        .experience-small {
          font-size: 1rem;
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          text-align: left;
          padding-left: 2rem;
        }
      }
      &-item {
        list-style: none;
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .how-type {
    &__content {
      padding: 15rem 12rem;
      &--list {
        &.technology {
          .how-type__content--list-item {
            margin-bottom: 5rem;
          }
        }
        &.experience {
          padding: 0 3rem;
          .how-type__content--list-item {
            margin: 0 0 2rem 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .how-type {
    &__content {
      padding: 8rem 1rem;
      &--list {
        &.skills {
          .how-type__content--description {
            margin: 6rem 3rem 6rem;
          }
        }
        &.experience {
          .how-type__content--list-item {
            max-width: none;
            width: 100%;
            .experience-big {
              font-size: 4.2rem;
            }
            .experience-small {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
